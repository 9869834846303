<template>
  <div class="text-container">
    <div class="text-content">

      <div class="header-text font-dark">
        Congratulations!
      </div>
      <p class="sub-text">
        You have secured your unit at {{ title }}.
      </p>
      <p class="p-text mt-5">
        You will receive an email with further instructions on the next steps.
      </p>
      <p class="p-text mt-5">
        One of our sales team will also be in contact with you in the next 24hrs.
      </p>

      <a
        href="/"
        class="block w-72 mt-8 p-4 text-sm text-center font-semibold leading-5 shadow text-white transition-colors duration-150 border border-transparent rounded-lg focus:outline-none uppercase focus:shadow-outline-blue bg-primary active:bg-secondary hover:bg-secondary"
      >
        Back to homepage
      </a>

      <!-- URL Link Content -->
      <div
          v-for="globalSetting in globalSettings"
          :key="globalSetting"
      >
        <a
            v-if="globalSetting.isUrlLink"
            :href="globalSetting.urlLink"
            target="_blank"
            class="block w-72 mt-8 p-4 text-sm text-center font-semibold leading-5 shadow text-white transition-colors duration-150 border border-transparent rounded-lg focus:outline-none uppercase focus:shadow-outline-blue bg-primary active:bg-secondary hover:bg-secondary"
        >
          {{globalSetting.urlLinkButton}}
        </a>
      </div>

    </div>
  </div>
</template>

<script>
import {
  APP_TITLE,
  PAYFAST_NOTIFY_URL,
} from '@/Constants'
import axios from "axios";
import {settingsCollection} from "@/firebase";
import JSConfetti from 'js-confetti'

export default {
  name: 'ThankYou',
  props: {
    unit: {
      default: "",
    },
    // settings: {
    //   urlLink: null,
    //   urlLinkButton: null,
    //   default: [],
    // },
  },
  data: () => ({
    now: new Date(),
    title: APP_TITLE,
    unitReservedUrl: PAYFAST_NOTIFY_URL,
    globalSettings: [],
    jsConfetti: new JSConfetti()

  }),
  methods: {
    // getSettings() {
    //   settingsCollection.doc("globalSettings").onSnapshot((doc) => {
    //     this.settings.isUrlLink = doc.data().isUrlLink || false;
    //     this.settings.urlLink = doc.data().urlLink || null;
    //     this.settings.urlLinkButton = doc.data().urlLinkButton || null;
    //   });
    // },
    reserveUnit() {
      axios
        .post(this.unitReservedUrl, {
          payment_status: "success",
          item_name: this.unit,
        })
        .then(() => {
          // Do nothing...
          // unitsCollection
          //     .doc("name", "==", this.unit)
          //     .update({
          //       paymentPaidOn: firebase.firestore.Timestamp.now(),
          //       paymentStatus: "success",
          //     })
        })
        .catch((error) => {
          this.$notify({
            group: "global",
            type: "error",
            title: "Error Sending Unit Reserved Mail",
            text: error.message,
          });
        });
    },
  },
  created() {
    this.jsConfetti.addConfetti();
    // this.getSettings()
    this.reserveUnit();

    settingsCollection.orderBy("isUrlLink").onSnapshot((snap) => {
      let globalSettings = [];

      snap.forEach((doc) => {
        globalSettings.push({
          isUrlLink: doc.data().isUrlLink,
          urlLink: doc.data().urlLink,
          urlLinkButton: doc.data().urlLinkButton,
        });
      });
      this.globalSettings = globalSettings;
    });
  }
}
</script>

<style>
.text-container {
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}

.text-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.header-text {
  font-size: 3rem;
  line-height: 1;
  font-weight: 700;
}

.sub-text {
  margin-top: 0.5rem;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 300;
}

.p-text {
  font-size: 1rem;
  max-width: 48rem;
  text-align: center;
}

@media (max-width: 768px) {
  .text-container {
    height: 75vh;
  }
  .header-text {
    font-size: 2rem;
    text-align: center;
  }

  .sub-text {
    font-size: 1rem;
    line-height: 1.125rem;
    text-align: center;
    font-weight: 300;
  }

  .p-text {
    font-size: .8rem;
    max-width: 24rem;
  }
}

@media (max-width: 375px) {
  .p-text {
    max-width: 20rem;
  }
}

@media (max-width: 320px) {
  .p-text {
    max-width: 16rem;
  }
}
</style>
